import { Atom } from ":mods";

export function createSubmissionFilters() {
  const IsSaudi = Atom.Form.createInput(undefined, {
    required: false,
  });
  const SpeakEnglish = Atom.Form.createInput(undefined, {
    required: false,
  });
  const Commission = Atom.Form.createInput(undefined, {
    required: false,
  });
  const WorkForMoc = Atom.Form.createInput(undefined, {
    required: false,
  });
  const EnglishLevel = Atom.Form.createSelect(undefined, {
    required: false,
  });
  const Region = Atom.Form.createSelect(undefined, {
    required: false,
  });
  const WhichCommission = Atom.Form.createSelect(undefined, {
      required: false,
    });

  const Actions = Atom.Form.createFormActions({
    IsSaudi,
    SpeakEnglish,
    Commission,
    WorkForMoc,
    EnglishLevel,
    Region,
    WhichCommission
  });

  return {
    Actions,
    IsSaudi,
    SpeakEnglish,
    Commission,
    WorkForMoc,
    EnglishLevel,
    Region,
    WhichCommission
  };
}
